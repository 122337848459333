import { Card, Col, Radio, Row } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { ContentWrapper, SettingsNavigation } from '~components';
import { useSettingsStore, useWindowDimensions } from '~hooks';

import styles from './styles.module.scss';

const PAGE_TITLE = 'Лого в хедере';

const DefaultLogo = (
  <svg width="106" height="40" viewBox="0 0 106 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11 0H0V40H26V31.0108H11V0Z" fill="#FF0A0A" />
    <path fillRule="evenodd" clipRule="evenodd" d="M31 40H44V0H31V40Z" fill="#FF0A0A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49 40H60V24.2045H73V15L60 15.0108V9L76 8.98916V0H49V40Z"
      fill="#FF0A0A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79 0V40H106V31.0099H90V24H102.895V15H90V8.93579H106V0H79Z"
      fill="#FF0A0A"
    />
  </svg>
);

const NewYearLogo = (
  <svg width="105" height="62" viewBox="0 0 105 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12829_5520)">
      <mask id="mask0_12829_5520" maskUnits="userSpaceOnUse" x="0" y="0" width="106" height="62">
        <path d="M0 0H106V62H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_12829_5520)">
        <path
          d="M11.1436 20.0064H0V61.9998H26.927V52.5615H11.1436V20.0064ZM31.1018 20.0064H42.2985V61.9998H31.1018V20.0046V20.0064ZM47.4949 61.9998H58.6385V45.4164H71.0107V35.9781H58.6385V29.4392H74.3725V20.0064H47.4931L47.4949 61.9998ZM88.9694 52.5615V45.4164H101.69V35.9781H88.9694V29.388H105V20.0064H77.8807V61.9998H104.998V52.5615H88.9694ZM23.3565 10.7019C26.4052 11.4473 28.1245 17.6913 28.1611 17.7609L47.5645 17.2847L48.2731 16.6876L56.3735 9.90144C56.3735 9.90144 51.5177 -5.14158 32.118 1.86434C29.6544 2.77153 27.264 3.86638 24.9678 5.13926L23.3565 10.7019Z"
          fill="#FF0A0A"
        />
        <path
          d="M44.9223 12.2313C45.1658 14.6582 46.4292 16.0282 48.2474 16.6821L56.3478 9.89597C56.3478 9.89597 51.5176 -5.14155 32.1179 1.86436C32.1179 1.86436 43.8145 1.19033 44.9223 12.2313Z"
          fill="#E61919"
        />
        <path
          d="M25.5617 5.6593C26.3406 8.50451 24.8142 11.4683 22.0715 12.2312C19.3287 12.9965 16.5089 11.2426 15.7295 8.39605C14.9505 5.55069 16.477 2.58715 19.2194 1.82235L25.5617 5.6593ZM25.5617 5.6593C24.7824 2.81298 21.9626 1.05685 19.2195 1.82233L25.5617 5.6593Z"
          fill="#F1F2F2"
          stroke="#9A9A9A"
        />
        <path
          d="M22.2928 6.56613C22.0418 5.59182 21.4963 4.71872 20.7309 4.06598C19.9684 3.41633 19.0225 3.02065 18.0247 2.93404C18.4293 2.65197 18.8797 2.4395 19.354 2.30763C21.8021 1.62261 24.3655 3.18497 25.0796 5.79135C25.7919 8.39774 24.3838 11.0664 21.9339 11.7569C21.4578 11.8906 20.9653 11.9401 20.4746 11.9071C21.2875 11.3173 21.9009 10.4858 22.2269 9.52967C22.5528 8.57174 22.5747 7.53688 22.2928 6.56613Z"
          fill="#ECECEC"
        />
        <path
          d="M29.0304 28.4309L29.0318 28.4316C29.3539 28.6067 29.7097 28.7107 30.0754 28.7366C30.441 28.7625 30.808 28.7097 31.1515 28.5818L31.1526 28.5814C40.2865 25.1561 49.7006 22.5312 59.2888 20.7361C59.652 20.6681 59.9963 20.5226 60.2983 20.3096C60.5997 20.097 60.8519 19.822 61.0378 19.5033C61.225 19.1846 61.3424 18.8297 61.3823 18.4622C61.4216 18.0989 61.3843 17.7314 61.2727 17.3835L59.65 11.4554C59.452 10.7272 58.9961 10.0958 58.3671 9.67889L58.367 9.67882C57.7395 9.26319 56.9813 9.09264 56.2364 9.19962L56.2257 9.20114L56.2152 9.20312C53.3327 9.74414 50.4647 10.3591 47.6138 11.0474C40.9288 12.6585 34.3483 14.6757 27.9087 17.0876L27.8979 17.0916L27.8874 17.0961C27.1907 17.3942 26.6274 17.9374 26.3043 18.6229C25.9809 19.3076 25.9165 20.0863 26.1229 20.8148C26.1232 20.8156 26.1234 20.8164 26.1236 20.8172L27.7458 26.7434C27.8293 27.0973 27.9859 27.4298 28.2056 27.7196C28.4273 28.0122 28.7084 28.2545 29.0304 28.4309Z"
          fill="#F1F2F2"
          stroke="#9A9A9A"
        />
        <path
          d="M51.5159 21.8857C54.0633 21.2838 56.6244 20.7415 59.1971 20.2593C59.4914 20.2053 59.7705 20.0881 60.0152 19.9158C60.2599 19.7434 60.4643 19.5201 60.6143 19.261C60.7664 19.0015 60.8611 18.7125 60.892 18.4132C60.923 18.114 60.8895 17.8117 60.7937 17.5265L59.1678 11.5866C59.0018 10.9753 58.6191 10.4454 58.0911 10.0957C57.5659 9.74775 56.9312 9.605 56.3077 9.69454C53.4336 10.234 50.5738 10.8472 47.7312 11.5335C47.7312 11.5335 52.642 14.7406 51.5159 21.8857Z"
          fill="#ECECEC"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_12829_5520">
        <rect width="105" height="62" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SpecialLogo = (
  <svg width="106" height="40" viewBox="0 0 106 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11 0H0V40H26V31.0108H11V0Z" fill="#424242" />
    <path fillRule="evenodd" clipRule="evenodd" d="M31 40H44V0H31V40Z" fill="#424242" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49 40H60V24.2045H73V15L60 15.0108V9L76 8.98916V0H49V40Z"
      fill="#424242"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79 0V40H106V31.0099H90V24H102.895V15H90V8.93579H106V0H79Z"
      fill="#424242"
    />
  </svg>
);

const CardData = observer(({ type }) => {
  const { logoType } = useSettingsStore();

  const getTitle = () => {
    switch (type) {
      case 'default': {
        return 'Классический';
      }
      case 'newYear': {
        return 'Новогодний';
      }
      case 'special': {
        return 'Специальный';
      }
      default: {
        return 'Неизвестный тип лого';
      }
    }
  };

  return (
    <Row>
      <Col>
        <Radio checked={logoType === type} />
        {getTitle()}
      </Col>
    </Row>
  );
});

const SettingsLogoPage = observer(() => {
  const { updateLogo, getLogoType } = useSettingsStore();

  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width < 480, [width]);

  useEffect(() => {
    getLogoType();
  }, [getLogoType]);

  const handleLogoClick = async type => {
    await updateLogo(type);
    getLogoType();
  };

  return (
    <>
      <SettingsNavigation />
      <ContentWrapper title={PAGE_TITLE}>
        <Row gutter={[20, 20]} className={styles.wrapper}>
          <div
            className={classNames(styles.card, isMobile && styles.isMobile)}
            onClick={() => handleLogoClick('default')}
          >
            <CardData type="default" />
            <Row justify="center" align="middle" className={styles.logo}>
              {DefaultLogo}
            </Row>
          </div>
          <div
            className={classNames(styles.card, isMobile && styles.isMobile)}
            onClick={() => handleLogoClick('newYear')}
          >
            <CardData type="newYear" />
            <Row justify="center" align="middle" className={styles.logo}>
              {NewYearLogo}
            </Row>
          </div>
          <div
            className={classNames(styles.card, isMobile && styles.isMobile)}
            onClick={() => handleLogoClick('special')}
          >
            <CardData type="special" />
            <Row justify="center" align="middle" className={styles.logo}>
              {SpecialLogo}
            </Row>
          </div>
        </Row>
      </ContentWrapper>
    </>
  );
});

export default SettingsLogoPage;
