import { YM_ID } from '~constants';
import { showErrorNotification } from '~utils';

declare global {
  interface Window {
    ym: any;
  }
}

export class YM {
  static init() {
    try {
      console.log('YM init...');
      window.ym(YM_ID, 'init', {
        // defer: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
    } catch (e) {
      showErrorNotification('Ошибка инициализации Яндекс Метрики', e);
    }
  }

  static sendEmployee(employeeId) {
    console.log('YM sendEmployee...', employeeId);
    window.ym && window.ym(YM_ID, 'userParams', { employeeId });
  }

  static sendVisit(url = window.location.pathname, parameters = {}) {
    console.log('YM sendVisit...', url, parameters);
    window.ym && window.ym(YM_ID, 'hit', url, parameters);
  }
}
