import '~stylesheets/index.scss';

import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import ModalContainer from 'react-modal-promise';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { LoadingWrapper, PublicRoute } from '~components';
import { YM_ID } from '~constants';
import { PrivateRoute } from '~containers';
import { useFlagsStore, useHasFocus, useNotificationsStore, useUserStore } from '~hooks';
import routes from '~routes';
import { initAnalytics } from '~services/analytics';

export const App = observer(() => {
  const { getUserInfo, isAuthenticated, checkPermissions } = useUserStore();
  const { setIsWindowFocused } = useNotificationsStore();
  const { getFlags, useWebVisor } = useFlagsStore();
  const hasFocus = useHasFocus();

  const ssoOrigin = process.env.LIFE_OAUTH_ORIGIN || 'https://sso.corr.life';

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      getUserInfo()
        .then(getFlags)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setIsWindowFocused(hasFocus);
  }, [hasFocus]);

  useEffect(() => {
    useWebVisor && initAnalytics();
  }, [useWebVisor]);

  const getRedirectPath = useCallback(
    (path: string) => {
      switch (path) {
        case '/': {
          if (checkPermissions('posts.view')) {
            return '/publications';
          }

          if (checkPermissions('videos.view')) {
            return '/broadcasts/video';
          }

          return '/publications';
        }
        case '/broadcasts': {
          if (checkPermissions('textTranslations.view')) {
            return '/broadcasts/text';
          }

          if (checkPermissions('videos.view')) {
            return '/broadcasts/video';
          }

          return path;
        }
        default:
          return path;
      }
    },
    [checkPermissions],
  );

  return (
    <LoadingWrapper isLoading={isLoading} title="Получение данных о пользователе...">
      <Router>
        <Switch>
          <Redirect exact from="/" to={getRedirectPath('/')} />
          <Redirect exact from="/broadcasts" to={getRedirectPath('/broadcasts')} />

          {/* Redirect for edit chrome extension */}
          <Redirect exact from="/posts/:id/edit" to="/publications/:id" />
          <Redirect exact from="/posts/:id" to="/publications/:id" />
          <Route
            path="/login"
            render={() => {
              localStorage.clear();
              window.location.href = `${ssoOrigin}/oauth/authorization?response_type=code&client_id=0b3395c6-728f-588a-9d48-d5bf5a61bc01&redirect_uri=${window.location.origin}/oauth/callback`;
              return null;
            }}
          />

          {Object.entries(routes.public).map(([key, route]) => {
            const { component, path } = route;
            return <PublicRoute key={key} component={component} path={path} exact />;
          })}

          {Object.entries(routes.private).map(([key, route]) => {
            const {
              submenu,
              component,
              path,
              defaultFilter,
              hideFilters = [],
              showPostsHeader = false,
              authority,
            } = route;
            const routes = [
              <PrivateRoute
                key={key}
                component={component}
                path={path}
                defaultFilter={defaultFilter}
                hideFilters={hideFilters}
                showPostsHeader={showPostsHeader}
                exact
                authority={authority}
              />,
            ];
            if (submenu) {
              Object.entries(submenu).forEach(([key, subRoute]) => {
                const { component, path, defaultFilter, hideFilters, authority } = subRoute;
                routes.push(
                  <PrivateRoute
                    key={key}
                    component={component}
                    path={path}
                    defaultFilter={defaultFilter}
                    hideFilters={hideFilters}
                    showPostsHeader={showPostsHeader}
                    authority={authority}
                  />,
                );
              });
            }
            return routes;
          })}
        </Switch>
        <div id="modal-container" style={{ position: 'relative', zIndex: 1000 }} />
        {useWebVisor && (
          <noscript>
            <div>
              <img
                src={`https://mc.yandex.ru/watch/${YM_ID}`}
                style={{ position: 'absolute', left: '-9999px' }}
                alt=""
              />
            </div>
          </noscript>
        )}
        <ModalContainer />
      </Router>
    </LoadingWrapper>
  );
});

export default hot(App);
