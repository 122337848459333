import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Col, Popover, Row } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { IReportsHistoryItem } from '~common';
import { EmptyContent } from '~components';
import { useReportsStore, useWindowDimensions } from '~hooks';
import { getShortText, getTimeByString } from '~utils';

import s from './styles.scss';

const TITLE_LENGTH = 15;

const getTitle = (title, length = TITLE_LENGTH): string => {
  return title?.length <= length ? (
    title
  ) : (
    <Popover content={title}>{getShortText(title, length)}</Popover>
  );
};

const getStatusIcon = status => {
  switch (status) {
    case 'Finished': {
      return getStatusPopover('Отчет сформирован', 'check-circle', s.success);
    }
    case 'Pending': {
      return getStatusPopover('В ожидании', 'plus-circle', s.new);
    }
    case 'FAILED': {
      return getStatusPopover('Ошибка формирования', 'close-circle', s.fail);
    }
    case 'Processing': {
      return getStatusPopover('Формируется', 'clock-circle', s.new);
    }
    default: {
      return getStatusPopover('Неизвестный статус', 'question-circle');
    }
  }
};

const getStatusPopoverContent = text => (
  <div className={s.popoverContent}>
    <p>{text}</p>
  </div>
);

const getStatusPopover = (text, icon, className?) => {
  const popoverContent = getStatusPopoverContent(text);

  return (
    <Popover content={popoverContent} className={className}>
      <LegacyIcon type={icon} className={s.icon} />
    </Popover>
  );
};

const ReportsList = observer(() => {
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width < 480, [width]);

  const { reports } = useReportsStore();

  const history = useMemo(
    () => (
      <>
        <Row align="middle" gutter={24} className={s.headerRow}>
          <Col span={6}>Тип очета</Col>
          <Col span={5}>Дата создания</Col>
          <Col span={7}>Сотрудник</Col>
          <Col span={4}>
            <Row align="middle" justify="center">
              Статус
            </Row>
          </Col>
          <Col span={2} />
        </Row>
        {reports.map((report: IReportsHistoryItem) => {
          const isFinished = report.status === 'Finished';
          return (
            <Row align="middle" gutter={24} className={s.historyRow}>
              <Col span={6}>
                <Row className={cx(s.mainTitle, !isFinished && s.disabled)}>
                  <Col>{getTitle(report.title)}</Col>
                </Row>
              </Col>
              <Col span={5}>
                <Row align="middle" className={cx(s.timeStamp, !isFinished && s.disabled)}>
                  <Col>{getTimeByString(report.createdAt, 'DD/MM/YYYY')}</Col>
                </Row>
              </Col>
              <Col span={7}>
                <Row align="middle" className={cx(s.employeeRow, !isFinished && s.disabled)}>
                  {getTitle(`${report.createdBy?.firstName} ${report.createdBy?.lastName}`, 20)}
                </Row>
              </Col>
              <Col span={4}>
                <Row align="middle" justify="center">
                  {getStatusIcon(report.status)}
                </Row>
              </Col>
              <Col span={2}>
                <LegacyIcon
                  type="download"
                  className={cx(s.icon, !isFinished && s.disabled)}
                  onClick={() => window.open(report.file.url, '_blank')}
                />
              </Col>
            </Row>
          );
        })}
      </>
    ),
    [reports],
  );

  const mobileHistory = useMemo(
    () => (
      <>
        <Row align="middle" gutter={24} className={s.headerRow}>
          <Col span={11}>Дата создания</Col>
          <Col span={8}>
            <Row align="middle" justify="center">
              Статус
            </Row>
          </Col>
          <Col span={5} />
        </Row>
        {reports.map((report: IReportsHistoryItem) => {
          const isFinished = report.status === 'Finished';
          return (
            <Row align="middle" gutter={24} className={s.historyRow}>
              <Col span={11}>
                <Row align="middle" className={cx(s.timeStamp, !isFinished && s.disabled)}>
                  <Col>{getTimeByString(report.createdAt, 'DD/MM/YYYY')}</Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row align="middle" justify="center">
                  {getStatusIcon(report.status)}
                </Row>
              </Col>
              <Col span={5}>
                <LegacyIcon
                  type="download"
                  className={cx(s.icon, !isFinished && s.disabled)}
                  onClick={() => window.open(report.file.url, '_blank')}
                />
              </Col>
            </Row>
          );
        })}
      </>
    ),
    [reports],
  );

  if (reports.length === 0) return <EmptyContent text="Нет отчетов" />;

  return <>{!isMobile ? history : mobileHistory}</>;
});

export default ReportsList;
